import { Tabs, notification } from "antd";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import AppLayout from "../Layout";
import {
  getAgentActiveCases,
  getAgentCompletedCases,
  getAgentPendingCases,
} from "../../services/agentController";
import { AgentCasesTable } from "./Tables/AgentCasesTable";
import { useGlobalStore } from "../../contexts/StoreContext";
import { getCasePayload } from "../../utils";

const AgentDashboard = () => {
  const { state, dispatch } = useGlobalStore();
  const { activeTabKey } = state;
  //const [notificationMsg, setNotificationMsg] = useState<string>("");
  const [api, contextHolder] = notification.useNotification();
  const payload = getCasePayload(state);

  const {
    data: activeCases,
    isPending: isActiveCasesPending,
    isRefetching: isActiveCasesRefetching,
  } = useQuery({
    queryKey: ["agentActiveCases", payload],
    queryFn: () => getAgentActiveCases(payload),
    enabled: activeTabKey === "2",
    placeholderData: keepPreviousData,
  });
  const {
    data: pendingCases,
    isPending: isPendingCasesPending,
    isRefetching: isPendingCasesRefetching,
  } = useQuery({
    queryKey: ["agentPendingCases", payload],
    queryFn: () => getAgentPendingCases(payload),
    enabled: activeTabKey === "1",
    placeholderData: keepPreviousData,
  });
  const {
    data: completedCases,
    isPending: isCompletedCasesPending,
    isRefetchError: isCompletedCasesRefetching,
  } = useQuery({
    queryKey: ["agentCompletedCases", payload],
    queryFn: () => getAgentCompletedCases(payload),
    enabled: activeTabKey === "3",
    placeholderData: keepPreviousData,
  });

  const items = [
    {
      label: "Pending Case",
      key: "1",
      children: (
        <AgentCasesTable
          totalRecords={pendingCases?.totalResults}
          data={(pendingCases?.data && pendingCases?.data) || []}
          isLoading={isPendingCasesPending || isPendingCasesRefetching}
          status_type="pending"
        />
      ),
    },
    {
      label: "Active Cases",
      key: "2",
      children: (
        <AgentCasesTable
          totalRecords={activeCases?.totalResults}
          data={(activeCases?.data && activeCases?.data) || []}
          isLoading={isActiveCasesPending || isActiveCasesRefetching}
          status_type="active"
        />
      ),
    },
    {
      label: "Resolved Cases",
      key: "3",
      children: (
        <AgentCasesTable
          totalRecords={completedCases?.totalResults}
          data={(completedCases?.data && completedCases?.data) || []}
          isLoading={isCompletedCasesPending || isCompletedCasesRefetching}
          status_type="completed"
        />
      ),
    },
  ];
  
  const setActiveTabKey = (e: string) => {
    dispatch({ type: "SET_ACTIVE_TAB_KEY", payload: e });
  };

  const openNotification = (title?: string, description?: string) => {
    api.open({
      message: title ? title : "Solveesy",
      description: description ? description : "",
      duration: 3,
    });
  };

  useEffect(() => {
    const tempTableData = state.activeTabKey === "1" ? pendingCases : state.activeTabKey === "2" ? activeCases : completedCases;
    //console.log('#>>> tempTableData :: ', tempTableData);
    if (tempTableData && Object.hasOwnProperty.call(tempTableData, "info")) {
      // setNotificationMsg(tempTableData?.info);
      // if (notificationMsg !== tempTableData?.info) {
        openNotification("", tempTableData?.info);
      //}
    } //else {
      //setNotificationMsg("");
    //}
  }, [activeCases, pendingCases, completedCases]);

  return (
    <>
      {contextHolder}
      <AppLayout moduleName="Portfolio">
        <Tabs
          items={items}
          onChange={setActiveTabKey}
          activeKey={state.activeTabKey}
        ></Tabs>
      </AppLayout>
    </>
  );
};

export default AgentDashboard;
