import { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Modal, Row, Form, Typography, Select, notification, Input, Upload, Col } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { getWhatsappTeplates, sendWhatsapp } from "../../services/commonController";

import { isEmpty } from "../../utils";
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function WhatsAppModal(props: any) {
  const TAG = "WhatsAppModal: ";
  const [submittable, setSubmittable] = useState(false);
  const [templateMsg, setTemplateMsg] = useState("");
  const [selectedTemplateOption, setSelectedTemplateOption] = useState<any>({});
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(true);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { data: whatsappTemplatesResponse } = useQuery({
    queryKey: ["whatsapp-templates"],
    queryFn: getWhatsappTeplates,
  });
  const actionUrl = `${process.env.REACT_APP_API_BASE_URL}sendWhatsapp?caseId=${props?.caseId}&id=${selectedTemplateOption?.value}&vertical=${selectedTemplateOption?.children}`;

  const { mutate, isPending } = useMutation({
    mutationKey: ["send-sms"],
    mutationFn: sendWhatsapp,
    onSuccess: (res, variables, context) => {
      if (res.data.status === 200) {
        notification.success({ message: "Success", description: res.data.message });
        form.resetFields();
        setSubmittable(false);
        setFileList([]);
        props.setIsWhatsAppModalVisible(false);
        queryClient.invalidateQueries({
          queryKey: ["Whatsapp-logs"],
        });
        queryClient.invalidateQueries({
          queryKey: ["audit-logs"],
        });
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error.response.data.message });
      form.resetFields();
      setSubmittable(false);
      setFileList([]);
    },
  });

  useEffect(() => {
    if (isEmpty(selectedTemplateOption) == false) {
      const findElem = whatsappTemplatesResponse?.data.find(
        (element: any) => element.id == selectedTemplateOption?.key
      );
      setTemplateMsg(isEmpty(findElem) == true || isEmpty(findElem?.data) == true ? "" : findElem.data);
    }
  }, [selectedTemplateOption]);

  // console.log(TAG + " props ", props);
  // console.log(TAG + " whatsappTemplatesResponse ", whatsappTemplatesResponse);
  // console.log(TAG + " selectedTemplateOption ", selectedTemplateOption);
  // console.log(TAG + " templateMsg ", templateMsg);

  const uploadProps: UploadProps = {
    disabled: uploading,
    multiple: false,
    action: "#",
    accept: "xlsx, XLSX, xls, XLS",
    customRequest: () => {
      console.log(TAG + "custom request");
    },
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setUploading(true);
    },
    beforeUpload: (file: any) => {
      // const isXLSX = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      // console.log(TAG + " file size ", file.size);

      //file size validation
      // if (file.size > 5e+6) {
      //   notification.error({ message: "Error", description: "File should be under 5mb." });
      //   return false;
      // }

      //file type validation
      // if (!isXLSX) {
      //   notification.error({ message: "Error", description: "File format not supported. Please upload an XLSX file." });
      // } else {
      //   setFileList([...fileList, file]);
      // }

      setFileList([...fileList, file]);
      return true;
    },
    fileList,
    maxCount: 1,
  };

  const handleUpload = async () => {
    console.log("#>> handleUpload() => actionUrl :: ", actionUrl);
    setUploading(true);
    setSubmittable(false);
    // console.log(TAG + "handleUpload got called");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("attachment", file as FileType);
    });
    await fetch(actionUrl, {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        notification.success({ message: "Success", description: res?.message });
        queryClient.invalidateQueries({ queryKey: ["case-details"] });
      })
      .catch((err) => {
        setSubmittable(true);
        setUploading(false);
        notification.error({ message: "Error", description: err?.response?.data?.message });
      })
      .finally(() => {
        setUploading(false);
        setFileList([]);
        form.resetFields();
        setSubmittable(true);
        props.setIsWhatsAppModalVisible(false);
      });
  };

  return (
    <Modal width={800} open={props.open} footer={null} onCancel={() => props.setIsWhatsAppModalVisible(false)}>
      <Card title="Send WhatsApp Message">
        <Form
          {...layout}
          form={form}
          name="callResponse-form"
          onFinish={(values) => {
            console.log("#>> onFinish() :: values :: ", values);
            mutate({
              caseId: props.caseId,
              verticalId: values.whatsappTemplate,
              verticalName: selectedTemplateOption?.children,
            });
          }}>
          <Form.Item
            name="whatsappTemplate"
            label={<Text className="font-bold">WhatsApp Templates</Text>}
            rules={[{ required: true }]}>
            <Select
              onChange={(value, option) => {
                setSubmittable(true);
                setSelectedTemplateOption(option);
                setUploading(false);
              }}>
              {whatsappTemplatesResponse?.data?.map(({ vertical, id }: any) => {
                return (
                  <Option key={id} value={id}>
                    {vertical}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {templateMsg ? (
            <Form.Item name="templateBody" label={<Text className="font-bold">Preview</Text>}>
              <TextArea
                rows={7}
                placeholder={templateMsg}
                maxLength={20000}
                size="small"
                status=""
                variant="outlined"
                disabled={false}
                readOnly={true}
                value={templateMsg}
                className="text-dark"
              />
            </Form.Item>
          ) : (
            <div />
          )}

          <Row className="flex justify-end pt-4">
            <Col className="mr-2">
              <Upload {...uploadProps}>
                <Button shape="round" icon={<CloudUploadOutlined />} disabled={uploading} />
              </Upload>
            </Col>
            <Button type="primary" disabled={!submittable || isPending} htmlType="submit" onClick={handleUpload}>
              Send Text
            </Button>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
}
