import * as React from "react";
import * as XLSX from "xlsx";
import { UploadOutlined, CheckOutlined } from "@ant-design/icons";
import { Flex, Button, Input, Col, Row, Select, Space, Upload, notification } from "antd";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getMandatoryFeilds, updateMandatoryFields, createClient } from "../../../services/adminController";

interface MappingItem {
  key: string;
  value: string;
}

export const CreateTable: React.FC = ({}) => {
  const [states, setStates] = React.useState<{
    extractedList: [];
    nbfcName: string;
    selectedFields: any[];
    loader: boolean;
    message: string;
  }>({
    extractedList: [],
    nbfcName: "",
    selectedFields: [],
    loader: false,
    message: "",
  });
  //const queryClient = useQueryClient();

  const { data: mandatoryFields, isPending } = useQuery({
    queryKey: ["mandatory_fields"],
    queryFn: () => getMandatoryFeilds(),
  });

  const handleFileUpload = (event: any) => {
    //console.log("#>>> handleFileUpload() ==> event :: ", event);
    const isXLSX = event.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    //console.log("#>>> isXSLX :: ", isXLSX);
    if (!isXLSX) {
      notification.error({
        message: "Error",
        description: "File format not supported. Please upload an XLSX file.",
      });
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      // Read the file as an array buffer
      const data = e.target!.result;
      const workbook = XLSX.read(data, { type: "array" });

      // Get the first sheet
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert sheet to JSON (array of rows)
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Extract the first row
      const firstRowData: any = jsonData[0];
      //console.log("#>>> handleFileUpload() => firstRowData :: ", firstRowData);
      setStates((prev) => ({ ...prev, extractedList: firstRowData }));
    };

    // Read the file as an array buffer for xlsx to process
    reader.readAsArrayBuffer(event);
  };

  const onClear = (selectedValIndex: number) => {
    //console.log('#>> onClear() :: selectedValIndex :: ', selectedValIndex);
    const tempSelectedFields = states.selectedFields;
    //console.log("#>> onClear() :: tempSelectedFields :0: ", tempSelectedFields);
    const deleteItem = tempSelectedFields.findIndex((item: any) => item.key === states.extractedList[selectedValIndex]);
    //console.log('#>> onClear() :: deleteItem :: ', deleteItem);
    tempSelectedFields.splice(deleteItem, 1);
    //console.log("#>> onClear() :: tempSelectedFields :1: ", tempSelectedFields);
    setStates((prev) => ({ ...prev, selectedFields: tempSelectedFields }));
  };

  const RenderList: React.FC<{ items: string[]; index: number }> = React.useCallback(
    ({ items, index }) => {
      return (
        <Flex className="mt-4">
          <Col span={6}>
            <p>{items}:</p>
          </Col>
          <Col span={6}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Select Fields"}
              allowClear
              onClear={() => onClear(index)}
              value={states.selectedFields.find((item: any) => item.key === items)}
              onSelect={(e, options) => {
                if(Boolean(states.selectedFields[index])) {
                  onClear(index);
                }
                const selectedFieldsIndex = states.selectedFields.indexOf(items);
                if(selectedFieldsIndex === -1) {
                  setStates((prev) => ({
                    ...prev,
                    selectedFields: [...prev.selectedFields, { key: items, value: options.value }],
                  }));
                }
              }}
              options={mandatoryFields?.data?.data?.requiredFields
                ?.filter((ele: any) => !states.selectedFields.some((field: any) => field.value === ele?.name))
                ?.map((item: any) => ({
                  key: item.name,
                  value: item.name,
                  label: item.name,
                  mandatory: item.required,
                }))}
              optionRender={(option: any) => {
                return(
                  <Space>
                    <span>{option.label}</span>
                    {Boolean(option?.data?.mandatory) && <span style={{color: 'red'}}>{`*`}</span>}
                  </Space>
                )}}
            />
          </Col>
        </Flex>
      );
    },
    [isPending, states.selectedFields]
  );

  const updateClientList = async () => {
    const res = await createClient(states.nbfcName);
    //console.log('#>> updateClientList() :: res : ', JSON.stringify(res));
    if(res.status === 200) {
      // notification.success({ message: "Success", description: res.data?.data });
      setStates((prev) => ({...prev, message: res?.data?.message}));
      const result = createMapping(states.selectedFields, states.extractedList);
      const payload = {
        clientId: res.data?.data?.clientId,
        mapping: result.mapping,
      };
      //console.log("#>> payload :: ", JSON.stringify(payload));
      updateMandatoryFieldsHandler(payload);
    } else {
      notification.error({ message: "Error", description: res.data?.message });
    }
  };

  const { mutate: updateMandatoryFieldsHandler } = useMutation({
    mutationFn: updateMandatoryFields,
    mutationKey: ["updateMandatoryFields"],
    onSuccess: (res, variables, context) => {
      if (res.status === 200) {
        // console.log("#>> updateMandatoryFieldsHandler >> res :: ", res);
        // queryClient.invalidateQueries({ queryKey: ["users"] });
        // queryClient.invalidateQueries({ queryKey: ["managers"] });
        
        notification.success({ message: "Success", description: states.message });
        setStates((prev) => ({ ...prev, extractedList: [], nbfcName: "", selectedFields: [], message: '', loader: false }));
      }
    },
    onError: (error: any) => {
      console.error("#>> updateMandatoryFieldsHandler >> Error :: ", error);
      notification.error({ message: "Error", description: error.response.data.message });
      setStates((prev) => ({ ...prev, message: '', loader: false }));
    },
  });

  function createMapping(selectedArray: MappingItem[], totalKeys: string[]): { mapping: Record<string, string>[] } {
    const mappingObj: Record<string, string> = {};

    selectedArray.forEach((item) => {
      mappingObj[item.key] = item.value;
    });

    const result: Record<string, string> = {};

    totalKeys.forEach((key) => {
      if (mappingObj[key]) {
        result[key] = mappingObj[key];
      } else {
        result[key] = "N/A";
      }
    });

    totalKeys.forEach((key) => {
      if (!(key in result)) {
        result[key] = "N/A";
      }
    });

    return {
      mapping: [result],
    };
  }

  const validateData = () => {
    setStates((prev) => ({ ...prev, loader: true }));
    if (states.nbfcName.length <= 0) {
      notification.error({ message: "Error", description: "NBFC name is required." });
      setStates((prev) => ({ ...prev, loader: false }));
      return;
    }
    if(states.nbfcName.trim().length === 0) {
      notification.error({ message: "Error", description: "NBFC name cannot be empty." });
      setStates((prev) => ({ ...prev, loader: false }));
      return;
    }
    if (states.selectedFields.length < mandatoryFields?.data?.data?.requiredFields?.filter((item: any) => item.required === true)?.length) {
      notification.error({ message: "Error", description: "Please select all mandatory fields." });
      setStates((prev) => ({ ...prev, loader: false }));
      return;
    }

    updateClientList();
  };

  return (
    <div>
      <Row className="flex gap-4">
        <Col span={6}>
          <Input
            placeholder="Please enter Lender name"
            value={states.nbfcName}
            onChange={(e) => setStates((prev) => ({ ...prev, nbfcName: e.target.value }))}
          />
        </Col>
        <Upload
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet, .xls, .xlsx"
          maxCount={1}
          showUploadList={false}
          beforeUpload={handleFileUpload}
          onRemove={() => setStates((prev) => ({ ...prev, extractedList: [] }))}>
          {states.extractedList.length > 0 ? (
            <Button icon={<CheckOutlined />}>Change File</Button>
          ) : (
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          )}
        </Upload>
      </Row>
      {!isPending && states.extractedList.length > 0 && (
        <div className="mt-4">
          <p>
            <b>
              <u>Select Mandatory Fields</u>
            </b>
          </p>
          {states.extractedList.map((item: any, index: number) => (
            <RenderList key={index.toString()} index={index} items={item} />
          ))}
          <div style={{ marginTop: "50px", alignItems: "center", justifyItems: "center" }}>
            <Button
              type="primary"
              style={{ marginLeft: "200px" }}
              className="pl-10 pr-10"
              loading={states.loader}
              onClick={validateData}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
