import { useState, useCallback, useEffect } from "react";
import { Col, Pagination, Row, Table, Tooltip, notification } from "antd";
import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { generatePath, useNavigate } from "react-router-dom";

import { adminCasesTableColumn } from "./Columns";
import { AdvanceFilter } from "../../AdvanceFilter";
import { useGlobalStore } from "../../../contexts/StoreContext";
import { downloadCases } from "../../../services/commonController";
import { getCasePayload, getCasePayloadType, retCasesWord } from "../../../utils";
import { SearchBox } from "../../SearchBox";

export function AgentCasesTable(props: any) {
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchVal, setSearchVal] = useState<string[]>(() => {
    const savedInput = localStorage.getItem("searchInputVal");
    return savedInput ? JSON.parse(savedInput) : [];
  });
  const navigate = useNavigate();
  const { state, dispatch } = useGlobalStore();
  const { data, totalRecords, isLoading } = props;

  const toggleAdvanceFilter = () => setIsAdvanceFilterOpen(!isAdvanceFilterOpen);

  const payload = getCasePayload(state);
  const casePayloadType: any = getCasePayloadType(state.activeTabKey);

  useEffect(() => {
    localStorage.removeItem("searchInputVal");
  }, []);
  
  const handleOnChange = useCallback(
    (page: number, pageSize: number) => {
      dispatch({
        type: casePayloadType,
        payload: {
          ...payload,
          page,
          size: pageSize,
        },
      });
    },
    [dispatch, casePayloadType, payload]
  );

  const handleSorting = useCallback(
    (sortBy: string, sortOrder: string) => {
      dispatch({
        type: casePayloadType,
        payload: {
          ...payload,
          sortBy: sortBy || "",
          sortOrder: sortOrder || "",
        },
      });
    },
    [dispatch, casePayloadType, payload]
  );

  const getSource = useCallback(() => {
    let source = "";
    if (state.activeTabKey === "1") {
      source = "pending";
    }
    if (state.activeTabKey === "2") {
      source = "active";
    }
    if (state.activeTabKey === "3") {
      source = "completed";
    }
    return source;
  }, [state.activeTabKey]);

  const source = getSource();

  const handleDownload = useCallback(
    (source: string) => {
      downloadCases(source)
        .then((blob) => {
          notification.success({
            message: "Success",
            description: `${blob}`,
          });
        })
        .catch((error) => {
          console.error("Error downloading the cases:", error);
          notification.error({
            message: "Error",
            description: error?.response?.data?.error,
          });
        });
    },
    []
  );

  const handleOnSearch = useCallback(
    (ids: string[]) => {
      setSearchVal(ids);
      localStorage.setItem("searchInputVal", JSON.stringify(ids)); // Persist to localStorage
      dispatch({
        type: casePayloadType,
        payload: {
          ...payload,
          page: 1,
          loanIds: ids,
        },
      });
    },
    [dispatch, casePayloadType, payload]
  );

  const handleOnClear = useCallback(() => {
    setSearchVal([]);
    localStorage.removeItem("searchInputVal"); // Clear localStorage
    handleOnChange(1, 10);
  }, [handleOnChange]);

  const handleOnLoader = useCallback(() => {
    setLoader(true);
  }, []);
  
  return (
    <Col>
      <Row className="flex justify-between">
        <Col className="flex pb-1">
        <SearchBox
            placeholder="Search..."
            onLoader={handleOnLoader}
            onSearch={(val: string[]) => {
              handleOnSearch(val);
              setLoader(false);
            }}
            onClear={handleOnClear}
            loader={loader}
            initialValue={searchVal} // Pass the persisted value
          />
        </Col>
        <Col className="p-2 pr-4 flex gap-3 pb-4">
          <Tooltip title={`Filter ${retCasesWord(String(source))} data `}>
          {/* <Tooltip title="Case Filter"> */}
            <FilterOutlined
              className="cursor-pointer text-lg"
              onClick={toggleAdvanceFilter}
            />
          </Tooltip>
          <Tooltip title="Download data">
            <DownloadOutlined
              className="cursor-pointer text-lg"
              onClick={() => handleDownload(source)}
            />
          </Tooltip>
        </Col>
      </Row>
      {isAdvanceFilterOpen && (
        <Row className="pb-4">
          <AdvanceFilter
            toggleAdvanceFilter={() => setIsAdvanceFilterOpen(false)}
          />
        </Row>
      )}

      <Table
        loading={isLoading || loader}
        columns={adminCasesTableColumn}
        dataSource={data || []}
        size="middle"
        pagination={false}
        onRow={(rowInfo) => ({
          onClick: () => {
            localStorage.setItem("caseType", rowInfo?.bucketName);
            const detailsPath = generatePath("/portfolio/:id", { id: rowInfo.key.toString() });
            navigate(detailsPath);
            dispatch({ type: "SET_BUCKET_NAME", payload: rowInfo?.bucketName });
          },
        })}
        onChange={(selectedRowKeys, selectedRows, info: any) => {
          handleSorting(info?.field, info?.order);
        }}
        style={{ cursor: "pointer" }}
      />
      <Row className="flex justify-center pt-6 pb-6">
        <Pagination
          showSizeChanger
          total={totalRecords}
          onChange={handleOnChange}
          pageSize={payload.size}
          current={payload.page}
        />
      </Row>
    </Col>
  );
}
