import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Modal,
  Row,
  Form,
  Typography,
  Select,
  notification,
  Input
} from "antd";
import { getSmsTemplates, sendSMS, getSMSTemplatePreview } from "../../services/commonController";
import { useEffect, useState } from "react";

import { isEmpty } from "../../utils";

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function SmsModal(props: any) {

  const TAG = "SmsModal: ";
  const [form] = Form.useForm();
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [templateMsg, setTemplateMsg] = useState("");
  const [previewLoader, setPreviewLoader] = useState<boolean>(false);


  const queryClient = useQueryClient();
  const { data: smsTemplateResponse } = useQuery({ queryKey: ["sms-templates", , props.caseId], queryFn: () => getSmsTemplates({ caseId: props.caseId }) });
  const smsTemplatesList = smsTemplateResponse?.data || [];
  const [selectedTemplateOption, setSelectedTemplateOption] = useState<any>({});

  const { mutate, isPending } = useMutation({
    mutationKey: ["send-sms"],
    mutationFn: sendSMS,
    onSuccess: (res, variables, context) => {
      if (res.data.status === 200) {
        notification.success({ message: "Success", description: res.data.message });
        form.resetFields();
        setSubmittable(false);
        props.setIsSmsModalOpen(false);
        queryClient.invalidateQueries({ queryKey: ["sms-logs"] });
        queryClient.invalidateQueries({ queryKey: ["audit-logs"] });
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error.response.data.message });
      form.resetFields();
      setSubmittable(false);
    }
  });

  async function onSmsTemplateSelect(value: any){

    setSelectedTemplateOption(value);

    setPreviewLoader(true);
    // console.log(TAG + " preview api called ");
    try {
      const previewApiRes = await getSMSTemplatePreview({ caseId: props.caseId, tempId: value });
      // console.log(TAG + " preview api response ", previewApiRes);
      setTemplateMsg(isEmpty(previewApiRes?.data?.data) == true ? "" : previewApiRes?.data?.data);
    } catch (error: any) {
      setTemplateMsg("");
      notification.error({ message: "Error", description: (error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong! while fetching email preview.") });
    }
    setPreviewLoader(false);

    setSubmittable(true);
  }


  // console.log(TAG + " smsTemplatesList ", smsTemplatesList);
  // console.log(TAG + " selectedTemplateOption ", selectedTemplateOption);
  // console.log(TAG + " templateMsg ", templateMsg);


  return (
    <Modal
      width={800}
      open={props.open}
      footer={null}
      onCancel={() => props.setIsSmsModalOpen(false)}
    >
      <Card title="Send SMS">
        <Form
          {...layout}
          form={form}
          name="callResponse-form"
          onFinish={(values) =>
            mutate({ caseId: props.caseId, tempId: values.smsTemplate })
          }
          onValuesChange={() => setSubmittable(true)}
        >
          <Form.Item
            name="smsTemplate"
            label={<Text className="font-bold">SMS Templates</Text>}
            rules={[{ required: true }]}
          >
            <Select
              onChange={(value, option) => onSmsTemplateSelect(value)}
              disabled={previewLoader}
            >
              {smsTemplatesList?.map(({ id, tempName }: any) => {
                return (
                  <Option key={id} value={id}> {tempName} </Option>
                );
              })}
            </Select>
          </Form.Item>

          {templateMsg ?
            <Form.Item
              name="templateBody"
              label={<Text className="font-bold">Preview</Text>}
            >
              <TextArea
                rows={7}
                placeholder={templateMsg}
                maxLength={20000}
                size="small"
                status=""
                variant="outlined"
                disabled={false}
                readOnly={true}
                value={templateMsg}
                className="text-dark"
              />
            </Form.Item>
            : <div />}

          <Row className="flex justify-end pt-4">
            <Button
              htmlType="submit"
              type="primary"
              disabled={!submittable || isPending || previewLoader}
            >
              Send SMS
            </Button>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
}
