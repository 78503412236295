import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Modal, Row, Form, Typography, Select, notification, Col, Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { getEmailTemplates, sendEmail, getTemplatePreview } from "../../services/commonController";
import { useState } from "react";
import { isEmpty } from "../../utils";
import type { GetProp, UploadFile, UploadProps } from "antd";
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const { Text } = Typography;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function EmailModal(props: any) {
  const TAG = "EmailModal: ";
  const [submittable, setSubmittable] = useState<boolean>(false);

  const [previewLoader, setPreviewLoader] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(true);

  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { data: emailTemplateResponse } = useQuery({ queryKey: ["email-templates"], queryFn: getEmailTemplates });
  const actionUrl = `${process.env.REACT_APP_API_BASE_URL}sendEmail?caseId=${props.caseId}&tempId=${
    form.getFieldsValue().emailTemplate
  }`;

  const { mutate, isPending } = useMutation({
    mutationKey: ["send-email"],
    mutationFn: sendEmail,
    onSuccess: (res, variables, context) => {
      if (res.data.status === 200) {
        notification.success({ message: "Success", description: res.data.message });
        form.resetFields();
        setSubmittable(false);
        setFileList([]);
        props.setIsEmailModalOpen(false);
        queryClient.invalidateQueries({ queryKey: ["email-logs"] });
        queryClient.invalidateQueries({ queryKey: ["audit-logs"] });
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error.response.data.message });
      form.resetFields();
      setSubmittable(false);
      setFileList([]);
    },
  });

  async function onTemplateSelect(value: any) {
    setPreviewLoader(true);
    // console.log(TAG + " preview api called ");
    try {
      const previewApiRes = await getTemplatePreview({ caseId: props.caseId, tempId: value });
      // console.log(TAG + " preview api response ", previewApiRes);
      setPreviewData(isEmpty(previewApiRes?.data?.data) == true ? "" : previewApiRes?.data?.data);
    } catch (error: any) {
      setPreviewData("");
      notification.error({
        message: "Error",
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong! while fetching email preview.",
      });
    }
    setPreviewLoader(false);
    setUploading(false);
    setSubmittable(true);
  }

  const handleUpload = async () => {
    setUploading(true);
    setSubmittable(false);
    // console.log(TAG + "handleUpload got called");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("attachment", file as FileType);
    });
    await fetch(actionUrl, {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        notification.success({ message: "Success", description: res?.message });
        queryClient.invalidateQueries({ queryKey: ["case-details"] });
        
      })
      .catch((err) => {
        setSubmittable(true);
        setUploading(false);
        notification.error({ message: "Error", description: err?.response?.data?.message });
      })
      .finally(() => {
        setUploading(false);
        setFileList([]);
        form.resetFields();
        setSubmittable(true);
        props.setIsEmailModalOpen(false);
      });
  };

  const uploadProps: UploadProps = {
    disabled: uploading,
    multiple: false,
    action: "#",
    accept: "xlsx, XLSX, xls, XLS",
    customRequest: () => {
      console.log(TAG + "custom request");
    },
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setUploading(true);
    },
    beforeUpload: (file: any) => {
      // const isXLSX = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      // console.log(TAG + " file size ", file.size);

      //file size validation
      // if (file.size > 5e+6) {
      //   notification.error({ message: "Error", description: "File should be under 5mb." });
      //   return false;
      // }

      //file type validation
      // if (!isXLSX) {
      //   notification.error({ message: "Error", description: "File format not supported. Please upload an XLSX file." });
      // } else {
      //   setFileList([...fileList, file]);
      // }

      setFileList([...fileList, file]);
      return true;
    },
    fileList,
    maxCount: 1,
  };

  return (
    <Modal width={800} open={props.open} footer={null} onCancel={() => props.setIsEmailModalOpen(false)}>
      <Card title="Send Email">
        <Form
          {...layout}
          form={form}
          name="callResponse-form"
          onFinish={(values) => {
            // mutate({ caseId: props.caseId, tempId: values.emailTemplate });
            // if(fileList.length > 0) {
            //   handleUpload();
            // }
          }}>
          <Form.Item
            name="emailTemplate"
            label={<Text className="font-bold">Email Templates</Text>}
            rules={[{ required: true }]}>
            <Select onChange={(value, option) => onTemplateSelect(value)} disabled={previewLoader}>
              {emailTemplateResponse?.data?.map(({ name, id }: any) => {
                return (
                  <Option key={id} value={id}>
                    {" "}
                    {name}{" "}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row className="flex justify-end pt-4">
            <Col className="mr-2">
              <Upload {...uploadProps}>
                <Button shape="round" icon={<CloudUploadOutlined />} disabled={uploading} />
              </Upload>
            </Col>
            <Button
              htmlType="submit"
              type="primary"
              disabled={isPending || !submittable || previewLoader}
              loading={isPending}
              onClick={handleUpload}>
              Send Email
            </Button>
          </Row>
        </Form>

        <div
          className="mt-5"
          style={{
            position: "relative",
          }}>
          <div
            className=""
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}></div>
          <div dangerouslySetInnerHTML={{ __html: previewData }} />
        </div>
      </Card>
    </Modal>
  );
}
