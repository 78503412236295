export const AUTH_LOGIN_ENDPOINT = "auth/login";
export const AUTH_LOGOUT_ENDPOINT = "/auth/logout";
export const AGENT_ACTIVE_CASES_ENDPOINT = "/agent/active-cases";
export const AGENT_PENDING_CASES_ENDPOINT = "/agent/pending-cases";
export const AGENT_COMPLETED_CASES_ENDPOINT = "/agent/completed-cases";
export const ADMIN_GET_USERS_ENDPOINT = "/admin/get-users";
export const ADMIN_UPDATE_AGENTS = "/admin/update-agents";
export const COMMON_CLIENT_LIST_ENDPOINT = "/client-list";
export const ADMIN_CASE_DETAILS_ENDPOINT = "admin/case-details?procId=";
export const AGENT_CASE_DETAILS_ENDPOINT = "agent/case-details?procId=";
export const AGENT_MANAGER_CASE_DETAILS_ENDPOINT = "/agent-manager/case-details?procId=";
export const ADMIN_ACTIVE_CASES_ENDPOINT = "/admin/active-cases";
export const ADMIN_PENDING_CASES_ENDPOINT = "/admin/pending-cases";
export const ADMIN_COMPLETED_CASES_ENDPOINT = "/admin/completed-cases";
export const AGENT_MANAGER_ACTIVE_CASES_ENDPOINT = "/agent-manager/active-cases";
export const AGENT_MANAGER_PENDING_CASES_ENDPOINT = "/agent-manager/pending-cases";
export const AGENT_MANAGER_COMPLETED_CASES_ENDPOINT = "/agent-manager/completed-cases";
export const UPDATE_PASSWORD_ENDPOINT = "/update-password";
export const COMMON_AGENTS_ENDPOINT = "/common/get-agents";
export const ADMIN_MANAGERS_LIST_ENDPOINT = "/admin/get-managers";
export const ADMIN_ROLES_LIST_ENPOINT = "/admin/get-all-roles";
export const AGENT_MANAGER_ASSIGN_TO_AGENT_ENDPOINT = "/agent-manager/assign-to-agent";
export const AGENT_ADMISSION_STATUS_ENDPOINT = "/agent/admission-status";
export const ADMIN_CREATE_USER_ENDPOINT = "/admin/create-agents";
export const ADMIN_UPDATE_USER_STATUS_ENDPOINT = "admin/update-user-status";
export const AGENT_CALL_RESPONSE_ENDPOINT = "/agent/call-response?caseId=";
export const INITIATE_CALL_ENDPOINT = "/agent/initiate-call?caseId=";
export const PAST_CALL_DETAILS = "/get-last-call-response?caseId=";
export const SUBMIT_CALL_RESPONSE = "/agent/submit-call";
export const CALL_LOG_ENDPOINT = "/call-log?caseId=";
export const ADMIN_TRANSFER_TO_LEGAL = "/transfer-to-legal?caseId=";
export const MAIL_LOG_ENDPOINT = "/mail-log?caseId=";
export const SMS_LOG_ENDPOINT = "/sms-log?caseId=";
export const WHATSAPP_LOG_ENDPOINT = "/whatsapp-log?caseId=";
export const WORKFLOW_STATUS_ENPOINT = "/workflow-status";
export const DPD_STATUS_ENPOINT = "/dpd-status";
export const DOWNLOAD_CASES_ENDPOINT = "/download-bucket?source=";
export const FORGOT_PASSWORD_ENDPOINT = "/auth/forgot-password?email=";
export const RESET_PASSWORD_ENPOINT = "/reset-password";
export const SMS_TEMPLATES_ENDPOINT = "/sms-templates?caseId=";
export const PREVIEW_SMS = "/preview-sms";
export const SEND_SMS_ENDPOINT = "/sendSMS?caseId=";
export const WHATSAPP_TEMPLATES_ENDPOINT = "/whatsapp-templates";
export const SEND_WHATSAPP_ENDPOINT = "/sendWhatsapp?caseId=";
export const EMAIL_TEMPLATE_ENDPOINT = "/email-templates";
export const SEND_EMAIL_ENDPOINT = "/sendEmail?caseId=";
export const GET_USER_PROFILE_ENPOINT = "/user-profile?email=";
export const UPDATE_USER_PROFILE_ENDPOINT = "/update-user-profile?email=";
export const AUDIT_LOGS_ENDPOINT = "/common/case-audit?caseId=";
export const GET_RECORDING_ENDPOINT = "/agent/get-recording?";
export const POST_CASE_COMMENT_ENDPOINT = "/common/case-comment";
export const REFRESH_EMAIL_LOGS_ENDPOINT = "/email-info?caseId=";
export const PREVIEW_EMAIL = "/preview-email";
export const MARK_AS_PAID_ENDPOINT = "/agent/mark-as-paid?caseId=";
export const AGENT_TRANSCRIPT_ENDPOINT = "/agent/get-transcript?callUuid=";
export const DEMAND_NOTICE = "/send-demand-notice?caseId=";
export const LEGAL_NOTICE = "/send-legal-notice?caseId=";
export const JUDICIAL_ACTION = "/initiate-judicial-action?caseId=";
export const SERVER_HEALTH_ENDPOINT = "/public/health";
export const FETCH_MANDATORY_FIELD_ENDPOINT = "/common/required-field";
export const UPDATE_MANDATORY_FIELD_ENDPOINT = "/common/save-mappings";
export const CREATE_CLIENT_ENDPOINT = 'admin/create-client?clientName=';
export const FETCH_UPLOAD_HISTORY = '/common/history?page=';