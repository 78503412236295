import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { Col, Typography, Row, Table, Pagination, Button } from "antd";
import { getUploadHistory } from "../../../services/commonController";
import { UploadHistoryColumn } from "./UploadHistoryColumns";
import { ReloadOutlined } from "@ant-design/icons";

type props = {
  activeTab: string;
};

export const UploadHistory: React.FC<props> = ({ activeTab }) => {
  const [states, setStats] = React.useState({ loader: true, page: 1, pageSize: 10 });

  const { data, isPending, refetch } = useQuery({
    queryKey: ["uploadHistory"],
    queryFn: () => getUploadHistory(states.page, states.pageSize),
    // refetchOnWindowFocus: "always",
  });

  React.useEffect(() => {
    refetch();
  }, [activeTab, states.page, states.pageSize]);

  React.useEffect(() => {
    setTimeout(() => {
      setStats((prev) => ({ ...prev, loader: false }));
    }, 1500);
  }, [states.loader]);
  
  return (
    <Col>
      <Row className="flex justify-between">
        <Typography.Text
          style={{
            borderRadius: "10px",
            color: "white",
            fontWeight: "bold",
            fontSize: "17px",
          }}>
          {<span style={{ color: "black" }}>Upload History</span>}
        </Typography.Text>
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          disabled={isPending}
          onClick={() => {
            setStats((prev) => ({ ...prev, loader: true, page: 1, pageSize: 10 }));
            refetch();
          }}
        />
      </Row>
      <Table
        loading={isPending || states.loader}
        columns={UploadHistoryColumn}
        dataSource={data?.content || []}
        size="middle"
        pagination={false}
        style={{ cursor: "pointer", marginTop: "10px" }}
      />
      <Row className="flex justify-center pt-6 pb-6">
        <Pagination
          showSizeChanger
          pageSize={states.pageSize}
          total={data?.totalRecords}
          onChange={(page: number, pageSize: number) => setStats((prev) => ({ ...prev, page, pageSize }))}
          onShowSizeChange={(size: number) => setStats((prev) => ({ ...prev, pageSize: size }))}
          current={states.page}
        />
      </Row>
    </Col>
  );
};
