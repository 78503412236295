import * as React from "react";
import { Col, Row, Tabs } from "antd";
import { DataUpload as DataUploads } from "./components/Tab_1";
import { CreateTable } from "./components/Tab_2";
import AppLayout from "../../components/Layout";
import { getUserData } from "../../utils";

const DataUpload = () => {
  const [activeTab, setActiveTab] = React.useState("1");
  const userData = getUserData();

  const tabsData = [
    {
      label: "Upload",
      key: "1",
      children: <DataUploads activeTab={activeTab} />,
    },
    {
      label: "Create Lender",
      key: "2",
      children: <CreateTable />,
    },
  ];

  return (
    <AppLayout moduleName="Data Upload">
      {userData?.roleName === "Admin" ? (
        <Tabs activeKey={activeTab} items={tabsData} onChange={(key) => setActiveTab(`${key}`)} />
      ) : (
        <DataUploads activeTab={activeTab} />
      )}
    </AppLayout>
  );
};

export default DataUpload;
