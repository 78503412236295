import { TableColumnsType, Tooltip } from "antd";
import { formatDateTime } from "../../../utils";

export type UploadHistoryTableColumnsTypes = {
  key: React.Key;
  fileName: React.ReactNode;
  clientName: React.ReactNode;
  uploadDate: React.Key;
  status: React.ReactNode;
  summary: React.ReactNode;
};

export const UploadHistoryColumn: TableColumnsType<UploadHistoryTableColumnsTypes> = [
  {
    title: "File Name",
    dataIndex: "fileName",
    render: (name: string) => <span>{name || "-"}</span>,
  },
  {
    title: "Lender Name",
    dataIndex: "clientName",
    render: (name: string) => <span>{name || "-"}</span>,
  },
  {
    title: "Upload Date & Time",
    dataIndex: "uploadDate",
    render: (name: string) => <span>{formatDateTime(name) || "-"}</span>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (name: string) => <span>{name || "-"}</span>,
  },
  {
    title: "Summary",
    dataIndex: "summary",
    render: (name) => {
      if (!name) {
        return <span>-</span>;
      } else {
        return (
          <Tooltip title={"Please check mail for further details"}>
            <span className="flex">
              {name || "-"}
            </span>
          </Tooltip>
        );
      }
    },
  },
];
